import React, { FC } from 'react';
import styled from 'styled-components';

import { Box, BoxInner } from '~src/components/elements';

const Wrapper = styled.div`
  height: 60vh;
  width: 100%;
`;

export const GoogleMap: FC = () => (
  <Box as="section">
    <BoxInner>
      <Wrapper>
        <iframe
          title="Map"
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1478.7599117642!2d19.000167499969468!3d42.16056117385037!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xe6aed015373703a7!2sMaestral+Canj!5e0!3m2!1sen!2sus!4v1564968922982!5m2!1sen!2sus"
          width="100%"
          height="100%"
          frameBorder="0"
          style={{ border: 0 }}
          allowFullScreen
        />
      </Wrapper>
    </BoxInner>
  </Box>
);
